<nav class="navbar">
    <div class="container-fluid d-flex align-items-end">
        <a class="navbar-brand " href="#">
            <img src="./assets/logo.png" alt="logo" style="width: 135px; height: 40px;"
                class="d-inline-block align-text-top">
        </a>
        <div class="navbar-nav ml-auto d-flex align-items-end align-items-end">
            <div class="d-flex mr-2">
                <div class="d-flex flex-column justify-content-center align-items-center pt-4 pr-2">
                    <a href="http://" style="text-decoration: none; color: black;">
                        <!-- <h4 class="nomarginpadding" style="font-size: 15px; text-decoration: none;">SIGN IN</h4> -->
                    </a>
                </div>
                <!-- <div style=" padding-top: 20px; font-size: 25px;">|</div> -->
                <div class="d-flex flex-column justify-content-center align-items-center pt-4 pl-2">
                    <a href="http://" style="text-decoration: none; color: black;">
                        <!-- <h4 class="nomarginpadding" style="font-size: 15px; text-decoration: none;">JOIN</h4> -->
                    </a>
                </div>
            </div>
        </div>
        <!-- <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <div class="btn-group">
                    <a type="button" class="nav-link nav_right_font dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <img src="https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png"
                            class="rounded-circle mr-lg-4" width="30" height="30"
                            style="margin-right: 3px !important" />

                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item text-lowercase"></a>

                        <a class="dropdown-item">Change Password</a>
            <li class="dropdown-submenu cc">
                <a class="dropdown-item dropdown-toggle  ">Switch Property</a>
                <ul class="dropdown-menu dropdown-submenu" style="max-height: 200px !important; overflow: auto">
                    <li>
                        <a class="dropdown-item">
                            aaaa
                        </a>
                    </li>
                </ul>
            </li>
            <a class="dropdown-item"></a>
    </div>
    </div>
    </li>
    </ul> -->
        <div *ngIf="check()" ngbDropdown class="d-inline-block" style="padding-bottom:5px ;cursor: pointer;">
            <a id="dropdownMenuButton" ngbDropdownToggle><img
                    src="https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png"
                    class="rounded-circle mr-lg-4" width="30" height="30" style="margin-right: 3px !important" /></a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                <button ngbDropdownItem (click)="gotoProfile()">Profile</button>
                <button ngbDropdownItem (click)="logOut()">Log Out</button>

            </div>
        </div>
    </div>
</nav>
<hr style="width: 97%; padding-top: 0px; margin-top: 0px; margin-bottom:40px;">