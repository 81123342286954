<body>

	<div class="container" style="width: 100%;">
		<div class="img">
			<img src="../../assets/book3.jpg">
		</div>
		<div class="login-content">
			<form [formGroup]="LoginForm">

				<img src="../../assets/logo1.png">
				<h2 class="title">Welcome</h2>
				<div class="input-div one">
					<div class="i">
						<img src="../../assets/user.png" style="height: 20px;width: 20px;" alt="">
					</div>

					<div class="div">

						<!-- <h5>UserId</h5> -->
						<input type="email" class="form-control login_form_field" formControlName="Email"
							placeholder="Email Id" />
						<!-- <div
							style="color: red"
							*ngIf="Email.invalid && Email.touched"
							class="warning"
						  >
							<p *ngIf="Email.errors.email">Enter valid email!</p>
							<p *ngIf="Email.errors.required">Email is required!</p>
						  </div> -->
					</div>
				</div>
				<div class="input-div pass">
					<div style="cursor: pointer;" (click)="x.type=x.type=='password'?'text':'password'" class="i">


						<img *ngIf="x.type=='password'" style="height: 20px;width: 20px;" src="../../assets/hidden.png"
							class="icon" />
						<img *ngIf="x.type=='text'" style="height: 20px;width: 20px;" src="../../assets/show.png"
							class="sicon" />

					</div>
					<div class="div">

						<!-- <h5>Password</h5> -->
						<input #x type="password" class="form-control login_form_field" formControlName="Password"
							placeholder="Password" autocomplete="on" />

					</div>
				</div>

				<input class="btn btn-primary login_button form-control" type="submit" name="Login" (click)="login()" />
			</form>
		</div>
	</div>

</body>