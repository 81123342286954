import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbModal,
  ModalDismissReasons
} from '@ng-bootstrap/ng-bootstrap';
import { ModalContentComponent } from 'src/app/modal-content/modal-content.component';
import { UserData } from '../UserData/userdata';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  UserFlag: boolean = false;
  UserData: UserData;
  constructor(
    private _Router: Router,
    private _ModalService: NgbModal,
  ) { }

  ngOnInit(): void {

  }
  logOut() {
    sessionStorage.clear();
    let data = new UserData().clearData('userdata');
    sessionStorage.setItem('reloadflag', 'true')
    this.ngOnInit();
    this._Router.navigateByUrl('login');
  }
  gotoProfile() {

    this.ngOnInit();
    this._Router.navigateByUrl('profile');
  }
  check() {
    let data = new UserData().getData('userdata');
    let UserId = data._id;
    if (data && UserId) {
      this.UserFlag = true;
      return true;
    } else {
      this.UserFlag = false;
      return false;
    }
    console.log(data);
  }
}
