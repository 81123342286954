<div class="page-footer m-5" style="background-color: rgb(232,235,235); max-width: 95vw;" *ngIf="!hide">
  <!-- <div class="container text-center text-lg-left mt-5"> -->
  <div class="row justify-content-center">
    <div class="mr-auto d-flex flex-wrap">
      <div class="d-flex flex-column align-items-md-start">
        <div>
          <h4 class="mt-3 pl-5 mb-0 p-0" style="text-transform: uppercase; letter-spacing: 1.5px; font-size: 15px;">
            How Can We Help?</h4>
        </div>
        <div class="d-flex align-items-start flex-wrap ml-4 mt-3 pt-2">
          <div class="d-flex flex-column ml-4 ">
            <a class="nav_font" [href]="
            PropertyUnitDetails && PropertyUnitDetails.Contact
              ? 'tel:'+PropertyUnitDetails.Contact
              : 'tel:+17074160922'
          ">
              <h6 class="nomarginpadding" style="color: #6E819D; letter-spacing: 2px;" *ngIf="PropertyUnitDetails">
                {{PropertyUnitDetails.Contact ?PropertyUnitDetails.Contact:'(707)-416-0922'}}</h6>
            </a>
            <p style="font-size: 7px; letter-spacing: 1.10px; text-transform: uppercase; padding-left: 10px;"> Call Us
              On This Number</p>
          </div>
          <div class="mx-2" style="line-height: 17px; font-weight: 100; font-size: 23px;">|</div>
          <div class="d-flex flex-column">
            <a class="nav_font" [href]="
            PropertyUnitDetails && PropertyUnitDetails.Email
              ? 'mailto:'+PropertyUnitDetails.Email
              : 'mailto:support@simulas.in'
          ">
              <h6 class="nomarginpadding" style="color: #6E819D;">
                {{PropertyUnitDetails && PropertyUnitDetails.Email
                ?PropertyUnitDetails.Email:'support@simulas.in'}}</h6>
            </a>
            <p style="font-size: 7px; letter-spacing: 2.5px; text-transform: uppercase; "> Contact us on email</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row col-md-6 links_footer">
      <!-- <div class="col-md-1"></div> -->
      <!-- <div class="mt-4 mb-4 mr-lg-auto">
          <ul
            class="list-unstyled d-flex flex-column justify-content-center align-items-lg-start justify-content-md-center justify-content-sm-center align-items-sm-center"
            style="font-size:15px; padding-left: 15px;">
            <li class=""><a href="#"><u>Ezyinn discount Terms & Conditions</u></a></li>
            <li class=""><a href="#"><u> Global Privacy Statement</u></a></li>
            <li class=""><a href="#"><u> Personal Data Requests</u></a></li>
            <li class=""> <a href="#"><u> Cookies Statement</u></a></li>
          </ul>
        </div>
        <div class="text-left mt-4 mb-4 mr-lg-auto">
          <ul class="list-unstyled d-flex flex-column justify-content-center align-items-lg-start"
            style="font-size:15px; padding-left: 15px;">
            <li class=""><a href="#"><u> Do not Sell My Information</u></a></li>
            <li class=""><a href="#"><u> Modern Slavery and Human Trafficking</u></a></li>
          </ul>
        </div> -->
    </div>
  </div>
</div>