export class UserData {
    setData(obj: any, key: string) {
        localStorage.setItem(key, btoa(JSON.stringify(obj)));
        return true;
    }

    getData(key: string) {
        if (localStorage.getItem(key)) {
            return JSON.parse(atob(localStorage.getItem(key)));
        } else {
            return false;
        }
    }
    clearData(key: string) {
        localStorage.removeItem(key);
        return true;
    }
}
