import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import CryptoJS from "crypto-js";

@Injectable({
  providedIn: 'root',
})
export class ReservationModuleService {
  constructor(private _http: HttpClient) { }
  BaseURL: string = environment.backendurl;
  apiHelth(): any {
    return this._http.get(this.BaseURL);
  }

  readReservationDetails(data: any) {
    return this._http
      .post(this.BaseURL + 'publicapi/read_walkin_cost_booking_engine', data, {
        withCredentials: true,
        observe: 'body' as 'response',
      })
      .pipe(catchError(this.handleError));
  }
  //publicapi/read_property_unit_details
  readPropertyUnitDetails(data: any) {
    return this._http
      .post(this.BaseURL + 'publicapi/read_property_unit_details', data, {
        withCredentials: true,
        observe: 'body' as 'response',
      })
      .pipe(catchError(this.handleError));
  }

  //publicapi/read_property_unit_address_contact
  readPropertyUnitAddressContact(data: any) {
    return this._http
      .post(this.BaseURL + 'publicapi/read_property_unit_address_contact', data, {
        withCredentials: true,
        observe: 'body' as 'response',
      })
      .pipe(catchError(this.handleError));
  }

  getTodayDate(data): Observable<any> {
    return this._http
      .post(this.BaseURL + 'publicapi/read_today_date', data, {
        withCredentials: true,
        observe: 'body' as 'response',
      })
      .pipe(catchError(this.handleError));
  }

  getformatDateTime(date): any {
    try {
      if (typeof date === "string") {
        return date.split("T")[0].replace(/-/g, "/");
      }
      if (typeof date === "number") {
        // it is a timestamp
        if (date.toString().length === 13) return date;
      }
      if (typeof date === "object") {
        if (date.isLuxonDateTime) return date;
        var dd = date.getDate().toString();
        var mm = date.getMonth() + 1 + "";
        if (mm.toString().length == 1) {
          mm = "0" + mm;
        }
        if (dd.toString().length == 1) {
          dd = "0" + dd;
        }
        var yyyy = date.getFullYear();
        return `${yyyy}/${mm}/${dd}`;
      }
    } catch (error) {
      console.log(error);
      return date;
    }

  }
  //read_zipcode
  readZipcode(data: any) {
    return this._http
      .post(this.BaseURL + 'publicapi/read_zipcode', data, {
        withCredentials: true,
        observe: 'body' as 'response',
      })
      .pipe(catchError(this.handleError));
  }
  //create_reservation_booking_engine
  bookRoom(data: any) {
    //  data.CardDetails.CardNumber = this.encrypt(data.CardDetails.CardNumber.toString().slice(0, 16));
    //data.CardDetails.CVV = this.encrypt(data.CardDetails.CVV.toString());
    return this._http
      .post(
        this.BaseURL + 'publicapi/create_reservation_booking_engine',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }
  preAuth(data: any) {
    //  data.CardDetails.CardNumber = this.encrypt(data.CardDetails.CardNumber.toString().slice(0, 16));
    //data.CardDetails.CVV = this.encrypt(data.CardDetails.CVV.toString());
    return this._http
      .post(
        this.BaseURL + 'guest/shift4/pre-auth',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }
  encrypt = (text) => {
    const randomKey = environment.CARDDECRYPT;
    const iv = randomKey ? randomKey.slice(0, 16) : "";
    const decrypted = CryptoJS.AES.encrypt(
      text,
      CryptoJS.enc.Utf8.parse(randomKey),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CTR,
        padding: CryptoJS.pad.NoPadding,
      }
    );
    return decrypted.toString();
  };
  //create_reservation_booking_engine
  sendMailGuest(data: any) {
    return this._http
      .post(
        this.BaseURL + 'publicapi/send_mail_guest',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }

  login_guest(data: any) {
    return this._http
      .post(
        this.BaseURL + 'guest/guest_login',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }

  pay(data: any) {


    return this._http
      .post(this.BaseURL + 'admin/property_plan/', data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        })
      .pipe(catchError(this.handleError));
  }

  // handleError
  private handleError(error: HttpErrorResponse) {
    console.log(error);
    if (
      error.error.statusMessage ==
      'Authentication token expired, please login again'
    ) {
      localStorage.clear();

      window.location.href = environment.url + '/login';
      return throwError(error.error);
    } else {
      return throwError(error.error);
    }
  }
}
