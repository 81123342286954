import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReservationModuleService } from '../reservation/reservation-module.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalContentComponent } from 'src/app/modal-content/modal-content.component';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbModal,
  ModalDismissReasons
} from '@ng-bootstrap/ng-bootstrap';
import { UserData } from '../UserData/userdata';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    private _RES: ReservationModuleService,
    private _Router: Router,
    private _ModalService: NgbModal,
  ) { }
  LoginForm: FormGroup;
  Email: FormControl;
  Password: FormControl;
  UserData: UserData;
  ngOnInit(): void {
    if (sessionStorage.getItem('reloadflag')) {
      sessionStorage.removeItem('reloadflag')
      window.location.reload();
    }
    this.Email = new FormControl('', [Validators.required, Validators.email]);
    this.Password = new FormControl('', [
      Validators.required,
      Validators.minLength(4),
    ]);
    this.LoginForm = new FormGroup({
      Email: this.Email,
      Password: this.Password,
    });
  }
  back() {
    window.history.back();
  }
  login() {
    this._RES.login_guest(this.LoginForm.value).subscribe(
      (data: any) => {
        console.log(data);
        this.UserData = new UserData();
        this.UserData.setData(data.data, 'userdata');
        sessionStorage.setItem('reloadflag', 'true')
        this._Router.navigateByUrl('Dashboard');
      },
      (error) => {
        console.log(error);
        this.openErrorMsg(error.statusMessage);
      }

    )
  }
  openErrorMsg(str) {
    let cfm = this._ModalService.open(ModalContentComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    cfm.componentInstance.name = str;

    cfm.result.then((res) => {
      if (res) {
      }
    });
  }
}
