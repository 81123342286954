import { Component, OnInit } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UserData } from '../UserData/userdata';
import { ReservationModuleService } from '../../app/reservation/reservation-module.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  hide = false;
  PropertyUnitDetails: any;
  constructor(
    public Location: Location,
    private _Router: Router,
    private _Route: ActivatedRoute,
    private _RMS: ReservationModuleService,
  ) {
    if (this.Location.path() == '/login') {
      this.hide = true;
    } else {
      this.hide = false;
    }

  }

  ngOnInit(): void {
    let data = new UserData().getData('userdata');
    if (data && data.PropertyUnitId && data.PropertyUnitId[0]) {
      sessionStorage.setItem('PID', data.PropertyUnitId[0])
      this.addfooter()
    } else if (sessionStorage.getItem('RSD')) {
      let y = JSON.parse(sessionStorage.getItem('RSD'))
      if (y && y.PropertyUnitId) {
        sessionStorage.setItem('PID', y.PropertyUnitId);
        this.addfooter()
      }
    } else {
      this._Route.queryParams.subscribe(async (params) => {
        let x: string;
        x = params['data'];
        if (x) {
          let UrlDetails = JSON.parse(x);
          if (UrlDetails) {
            if (UrlDetails.PID) {
              sessionStorage.setItem('PID', UrlDetails.PID)
              this.addfooter()
            }
          }
        }
      });
    }
  }
  addfooter() {
    let x = sessionStorage.getItem('PID');
    if (x) {
      this._RMS
        .readPropertyUnitAddressContact({
          PropertyUnitId: x,
        })
        .subscribe(
          (data: any) => {
            console.log(data);
            this.PropertyUnitDetails = data.data.PropertyUnitDetails[0];
          },
          (e) => console.log(e.statusMessage)
        );
    }
  }
}
