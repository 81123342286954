import { Component, OnInit } from '@angular/core';
import { GuestOperationService } from '../../app/guest-operation/guest-operation.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReservationModuleService } from '../reservation/reservation-module.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentComponent } from 'src/app/modal-content/modal-content.component';
import { UserData } from '../UserData/userdata';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  Reservations
    : any;
  UserForm: FormGroup;

  constructor(
    private _GES: GuestOperationService,
    private _RMS: ReservationModuleService,
    private _ModalService: NgbModal
  ) { }

  ngOnInit(): void {
    let data = new UserData().getData('userdata');
    let UserId = data._id;
    if (UserId) {
      // this._GES.readReservation({ UserId: UserId }).subscribe(
      //   (data: any) => {
      //     console.log(data);
      //     this.Reservations = data.data.Reservations;
      //     let arr = [];
      //     let i = 0;
      //     this.Reservations = this.Reservations[0]

      //     console.log(this.Reservations);




      //   },
      //   (error) => {
      //     console.log(error);
      //   }

      // )
      this.UserForm = new FormGroup({
        FirstName: new FormControl('', [
          Validators.required,
          Validators.pattern(/^\S+(?: \S+)*\s*$/),
        ]),
        LastName: new FormControl('', [
          Validators.required,
          Validators.pattern(/^\S+(?: \S+)*\s*$/),
        ]),
        // AddressStyleId: new FormControl('5fb454452045126a25d84af2'),
        AddressLine1: new FormControl('', [Validators.required, Validators.pattern(/^\S+(?: \S+)*\s*$/)]),
        AddressLine2: new FormControl('', Validators.pattern(/^\S+(?: \S+)*\s*$/)),
        ZipCode: new FormControl('', [
          Validators.required,
          Validators.pattern(/\b\d{5}\b/),
        ]),
        Country: new FormControl('USA', [Validators.required, Validators.pattern(/^\S+(?: \S+)*\s*$/)]),
        State: new FormControl('', [Validators.required, Validators.pattern(/^\S+(?: \S+)*\s*$/)]),
        City: new FormControl('', [Validators.required, Validators.pattern(/^\S+(?: \S+)*\s*$/)]),
        Phone: new FormControl('', [Validators.required]),
        Email: new FormControl('', [Validators.required]),
        // Mobile: new FormControl(''),

        // DOB: new FormControl('', Validators.required),
        // UserId: new FormControl(''),
      });
      this._GES.readUserProfile({ UserId: UserId }).subscribe(
        (data: any) => {
          console.log(data);

          this.UserForm['controls']['FirstName'].setValue(
            data.data.User[0].Name.First
          );
          this.UserForm['controls']['LastName'].setValue(
            data.data.User[0].Name.Last
          );
          this.UserForm['controls']['AddressLine1'].setValue(
            data.data.User[0].AddressDetails.AddressLine1
          );
          this.UserForm['controls']['AddressLine2'].setValue(
            data.data.User[0].AddressDetails.AddressLine2
          );

          this.UserForm['controls']['Country'].setValue(
            data.data.User[0].AddressDetails.Country
          );
          this.UserForm['controls']['State'].setValue(
            data.data.User[0].AddressDetails.State
          );
          this.UserForm['controls']['City'].setValue(
            data.data.User[0].AddressDetails.City
          );
          this.UserForm['controls']['ZipCode'].setValue(
            data.data.User[0].AddressDetails.ZipCode
          );

          this.UserForm['controls']['Phone'].setValue(
            data.data.User[0].ContactDetails.Phone
          );
          this.UserForm['controls']['Email'].setValue(
            data.data.User[0].Email
          );




        },
        (error) => {
          console.log(error);
        }

      )
    }
  }
  getZipCodeData(event) {
    if (event.target.value.length >= 5) {

      this._RMS.readZipcode({ ZipCode: event.target.value }).subscribe(
        (data: any) => {

          if (data !== 'undefined') {
            this.UserForm['controls']['City'].setValue(data.data.ZipCode.city);
            this.UserForm['controls']['State'].setValue(
              data.data.ZipCode.fullState
            );
          }
        },
        (error) => {
          this.openErrorMsg(error.statusMessage);
        }
      );
    } else {
      this.UserForm['controls']['City'].setValue('');
      this.UserForm['controls']['State'].setValue('');
    }
  }
  openErrorMsg(str) {
    let cfm = this._ModalService.open(ModalContentComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    cfm.componentInstance.name = str;

    cfm.result.then((res) => {
      if (res) {
      }
    });
  }
}
