import { Component, OnInit } from '@angular/core';
import { MixpanelServiceService } from './Services/mixpanel-service.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(private _MIX: MixpanelServiceService) { }
  ngOnInit(): void {
    this._MIX.init();
    this.add_cdn()
  }
  title = 'ezyinn';
  async add_cdn() {
    // var jquery = window.document.createElement("script");
    // jquery.src = `https://code.jquery.com/jquery-3.6.0.min.js`;
    // window.document.body.appendChild(jquery);
    var truetoken = window.document.createElement('script');
    truetoken.type = 'text/javascript';
    truetoken.src = `${environment.SHIFT4_URL}/js/jquery.i4goTrueToken.js`;
    window.document.body.appendChild(truetoken);
  }
}
