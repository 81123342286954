<div class="row mx-5 justify-content-between">
    <div class="col-lg-12 col-md-12 col-sm-12 ">
        <div class="data">
            <div class="mb-1">
                <h3 style="text-transform: uppercase">Profile</h3>

            </div>
            <div class=" mt-3">


                <!-- <p> PNR0089277939</p> -->
            </div>
        </div>
        <div class="d-flex mb-4">
            <hr class="bg-primary mb-2 mt-0 d-inline-block" style="width: 35%; height: 2px" />
            <hr class="bg-secondary mb-2 mt-0" style="width: 65%; height: 2px" />
        </div>
    </div>
</div>

<div class="container-fluid custom_container1 mt-4">
    <div class="col-lg-10">
        <div class="container-fluid custom_container2 my-3 pt-3 pb-1">
            <table [formGroup]="UserForm" class="table table-sm table-borderless add_table">
                <tr>
                    <td>
                        <div class="mt-2">First Name*:</div>
                    </td>
                    <td>
                        <input type="text" class="form-control field" formControlName="FirstName" />
                        <p class="error" *ngIf="
                UserForm.controls.FirstName.invalid &&
                UserForm.controls.FirstName.touched
              ">
                            Please enter first name!
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="mt-2">Last Name*:</div>
                    </td>
                    <td>
                        <input type="text" class="form-control field" formControlName="LastName" />
                        <p class="error" *ngIf="
                UserForm.controls.LastName.invalid &&
                UserForm.controls.LastName.touched
              ">
                            Please enter last name!
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="mt-2">Email*:</div>
                    </td>
                    <td>
                        <input type="text" class="form-control field" placeholder="Email" formControlName="Email" />
                        <p class="error" *ngIf="
                                                                    UserForm.controls.Email.invalid 
                                                                  ">
                            Please enter Email!
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="mt-2">Phone*:</div>
                    </td>
                    <td>
                        <input type="text" class="form-control field" placeholder="Street(Optional)"
                            formControlName="Phone" />
                        <p class="error" *ngIf="
                                                UserForm.controls.Phone.invalid 
                                              ">
                            Please enter Phone!
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="mt-2">Address:</div>
                    </td>
                    <td>
                        <input type="text" class="form-control field" placeholder="Street"
                            formControlName="AddressLine1" />
                        <p class="error" *ngIf="
                UserForm.controls.AddressLine1.invalid &&
                UserForm.controls.AddressLine1.touched
              ">
                            Please enter address!
                        </p>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <input type="text" class="form-control field" placeholder="Street(Optional)"
                            formControlName="AddressLine2" />
                        <p class="error" *ngIf="
                UserForm.controls.AddressLine2.invalid 
              ">
                            Please enter address!
                        </p>
                    </td>
                </tr>

                <tr>
                    <td></td>
                    <td>
                        <div class="d-flex">
                            <input type="text" class="form-control field" placeholder="Country" style="width: 49.5%"
                                formControlName="Country" />
                            <p class="error" *ngIf="
                  UserForm.controls.Country.invalid &&
                  UserForm.controls.Country.touched
                ">
                                Please enter country!
                            </p>
                            <input type="text" class="form-control field ml-1" placeholder="Zip code"
                                style="width: 49.5%" formControlName="ZipCode" (input)="getZipCodeData($event)" />
                            <p class="error" *ngIf="
                  UserForm.controls.ZipCode.invalid &&
                  UserForm.controls.ZipCode.touched
                ">
                                Please enter valid zipcode!
                            </p>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td class="pb-3">
                        <div class="d-flex">
                            <input type="text" class="form-control field" placeholder="City" style="width: 49.5%"
                                formControlName="City" />
                            <p class="error" *ngIf="
                  UserForm.controls.City.invalid &&
                  UserForm.controls.City.touched
                ">
                                Please enter valid city!
                            </p>
                            <input type="text" class="form-control field ml-1" placeholder="State" style="width: 49.5%"
                                formControlName="State" />
                            <p class="error" *ngIf="
                  UserForm.controls.State.invalid &&
                  UserForm.controls.State.touched
                ">
                                Please enter valid state!
                            </p>
                        </div>
                    </td>
                </tr>

                <!-- <tr>
                    <td>
                        <div class="mt-2">Phone*:</div>
                    </td>
                    <td>
                        <ngx-intl-tel-input [cssClass]="'form-control field'" [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true" [enablePlaceholder]="false" [searchCountryFlag]="true"
                            [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15"
                            [phoneValidation]="true" [separateDialCode]="false"
                            [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="Phone">
                        </ngx-intl-tel-input>
                        <p class="error" *ngIf="
                UserForm.controls.Phone.invalid &&
                UserForm.controls.Phone.touched
              ">
                            Please enter valid phone number!
                        </p>
                    </td>
                </tr> -->



                <tr>
                    <td></td>
                    <!-- <td>
                        <div class="d-flex justify-content-center">
                          
                            <button [disabled]="!UserForm.valid" class="btn btn-primary button ml-2"
                                (click)="updateUser()">
                                UPDATE
                            </button>
                        </div>
                    </td> -->
                </tr>
            </table>
        </div>
    </div>

</div>