import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import CryptoJS from "crypto-js";

@Injectable({
  providedIn: 'root'
})
export class GuestOperationService {

  constructor(private _http: HttpClient) { }
  BaseURL: string = environment.backendurl;
  apiHelth(): any {
    return this._http.get(this.BaseURL);
  }

  readReservation(data: any) {
    return this._http
      .post(
        this.BaseURL + 'guest/read_reservation',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }
  readGuestFolio(data: any) {
    return this._http
      .post(
        this.BaseURL + 'guest/guest_folio',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }
  checkUpdateStay(data: any) {
    return this._http
      .post(
        this.BaseURL + 'guest/check_multiple_update_stay',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }
  readProperty(data: any) {
    return this._http
      .post(
        this.BaseURL + 'guest/read_property_details',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }
  readVirtualCard(data: any) {
    return this._http
      .post(
        this.BaseURL + 'guest/read_virtual_card_payment',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }
  encrypt = (text) => {
    const randomKey = environment.CARDDECRYPT;
    const iv = randomKey ? randomKey.slice(0, 16) : "";
    const decrypted = CryptoJS.AES.encrypt(
      text,
      CryptoJS.enc.Utf8.parse(randomKey),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CTR,
        padding: CryptoJS.pad.NoPadding,
      }
    );
    return decrypted.toString();
  };
  addVirtualCard(data: any) {
    data.CardNumber = this.encrypt(data.CardNumber.toString().slice(0, 16));
    // data.CVV = this.encrypt(data.CVV.toString());

    return this._http
      .post(
        this.BaseURL + 'guest/add_virtual_card_payment',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }
  processVirtualCard(data: any) {
    return this._http
      .post(
        this.BaseURL + 'guest/virtual_card_payment',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }
  processMultiVirtualCards(data: any) {
    return this._http
      .post(
        this.BaseURL + 'guest/process_multiple_virtual_cards',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }
  step2Payment(data: any) {
    return this._http
      .post(
        this.BaseURL + 'guest/step2Payment',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }

  readWalkInCost(data: any) {
    return this._http
      .post(
        this.BaseURL + 'guest/change_room_cost',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }
  readPlan(data: any) {
    return this._http
      .post(
        this.BaseURL + 'guest/read_rate_plan',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }
  readCancellation(data: any) {
    return this._http
      .post(
        this.BaseURL + 'guest/read_cancellation_charges',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }
  readTax(data: any) {
    return this._http
      .post(
        this.BaseURL + 'guest/read_tax',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }
  readWalkinPayment(data: any) {
    return this._http
      .post(
        this.BaseURL + 'guest/read_walkin_payment',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }
  stayUpdate(data: any) {
    return this._http
      .post(
        this.BaseURL + 'guest/stay_update',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }
  cancelReservation(data: any) {
    return this._http
      .post(
        this.BaseURL + 'guest/cancel_reservation',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }
  assignRoom(data: any) {
    return this._http
      .post(this.BaseURL + 'guest/change_room', data, {
        withCredentials: true,
        observe: 'body' as 'response',
      })
      .pipe(catchError(this.handleError));
  }

  sendUpdateStayMail(data: any) {
    return this._http
      .post(this.BaseURL + 'guest/send_update_stay_mail', data, {
        withCredentials: true,
        observe: 'body' as 'response',
      })
      .pipe(catchError(this.handleError));
  }
  readRoomType(data: any) {
    return this._http
      .post(this.BaseURL + 'guest/read_room_types', data, {
        withCredentials: true,
        observe: 'body' as 'response',
      })
      .pipe(catchError(this.handleError));
  }

  readUserProfile(data: any) {
    return this._http
      .post(this.BaseURL + 'guest/read_user_profile', data, {
        withCredentials: true,
        observe: 'body' as 'response',
      })
      .pipe(catchError(this.handleError));
  }
  refundPayment(data: any) {
    return this._http
      .post(this.BaseURL + 'guest/frontdesk_refund', data, {
        withCredentials: true,
        observe: 'body' as 'response',
      })
      .pipe(catchError(this.handleError));
  }
  updateOccupancy(data: any) {
    return this._http
      .post(
        this.BaseURL + 'guest/update_guest_occupancy',
        data,
        {
          withCredentials: true,
          observe: 'body' as 'response',
        }
      )
      .pipe(catchError(this.handleError));
  }
  private handleError(error: HttpErrorResponse) {
    console.log(error);
    if (
      error.error.statusMessage ==
      'Authentication token expired, please login again'
    ) {
      localStorage.clear();

      window.location.href = environment.url + '/login';
      return throwError(error.error);
    } else {
      return throwError(error.error);
    }
  }
}
