// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mix: '136e4e22f78c0b344767cd39f9ca0d9c',
  url: 'https://bookingengine.ezyinn.net',
  backendurl: 'https://prod.ezyinn.net/ezyinn/',
  socketbackendurl: '/',
  S3AccessKeyID: 'AKIA3ACYYOBH472LCTOV',
  S3SecretAccessKey: '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',
  S3URL: 'https://ezyinnbucket.s3.us-west-1.amazonaws.com/',
  LOGODIR: 'LOGODIR/',
  COVERDIR: 'COVERDIR/',
  PROFILEDIR: 'PROFILEDIR/',
  Documents: 'Documents/',
  ROOMIMGDIR: 'ROOMIMGDIR/',
  CARDDECRYPT: 'manakravironakshereeeeeeeee1008!',
  STRIPE_PRIV_KEY: 'pk_live_51McSnsHzziVBvHPCMQkoobmy6ysuIXO44aJ9fEP89uCgA1RKs0mmTQRNWp5DMH8BjOcQptwj0mxPbK2GzaTb20kD00wSM8F0le',
  SHIFT4_URL: "https://i4m.i4go.com",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
